/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        var $body = $('body');
        // Hamburger Menu
        function toggleNavBar() {
          $('body').toggleClass('menu-visible');
        }
        $('#menu-toggle').click( toggleNavBar );

        // Form Select
        $("select").each(function() {
          $(this).wrapAll("<div class='select-wrapper'></div>");
        });

        /* NAV STUFF */
        $('header nav li.dropdown').append( '<span class="subnav-toggle ico ico-chevron-down"></span>' );

        $('li.dropdown > a').click( function(e){
          if( window.innerWidth < 768 ) {
            e.preventDefault();
          }
        });

        $('li.dropdown').click( function(e) {
          e.stopPropagation();
          $(this).toggleClass('dropdown-visible');
        });

        $("iframe[src*='youtube'],iframe[src*='vimeo']").each(function(){
          $(this).wrapAll("<div class='video-container'></div>");
        });

        /* Nav Tabs ( nesletters page ) */

        $('ul.nav-tabs li a').click( function(e){
          e.preventDefault();
          $('ul.nav-tabs li a').removeClass('active');
          $(this).addClass('active');
          $('.tab-pane').removeClass('active');
          $($(this).attr('href')).addClass('active');

        });

        //////////////////////////////////////////////////////////
        // Sidebar nav menus
        //////////////////////////////////////////////////////////
        if (location.pathname !== '/') {
          var submenuClass = false;

          // handle exceptions first
          if ($body.hasClass('blog') || $body.hasClass('category-cultural-events')) {
            submenuClass = 'nav-egypt-today';
          } else if ($body.hasClass('category-announcements') || $('article.category-announcements').length > 0) {
            submenuClass = 'nav-egyptian-community';
            $('#menu-primary-navigation li.menu-news,#menu-primary-navigation .nav-egypt-today').removeClass('active');
          } else if ($body.hasClass('post-type-archive-ambass_media')|| $body.hasClass('single single-ambass_media') ) {
            submenuClass = 'nav-the-embassy';
            $('#menu-primary-navigation li.menu-news,#menu-primary-navigation .menu-egypt-today').removeClass('active');
          } else if (
              $body.hasClass('single-post') ||
              $body.hasClass('post-type-archive-video') ||
              $body.hasClass('single-video') ||
              $body.hasClass('post-type-archive-event') ||
              $('article.type-event').length > 0
            )
          {
            submenuClass = 'nav-egypt-today';
          } else {
            var matches = location.pathname.match(/^\/([^\/]+)/i);
            // pull out first part of path to match against a class name
            if (matches && matches.length === 2) {
              submenuClass = 'nav-' + matches[1];
            } else {
              console.warn("Failed cloning sub-menu for slug" + matches[1], matches);
              console.log('Needs fixing, most likely');
            }
          }

          if (submenuClass) {
            var submenuSel = 'li.'+submenuClass;
            var tempId = 'TEMP-ID';
            $("#sidebar").append( $(submenuSel).clone().attr('id', tempId) );
            $('#'+tempId)
              .wrap('<nav class="navbar-menu" role="navigation"></nav>')
              .wrap('<ul class="secondary-nav" id="menu-secondary-navigation"></ul>')
              .addClass('sidebar-nav-menu');
            $('#'+tempId).removeAttr('id'); // house cleaning: get rid of TEMP-ID

            if ($body.hasClass('post-type-archive-video') || $body.hasClass('single-video')) {
              $("#sidebar li.dropdown.menu-news").addClass('active');
            } else if ($body.hasClass('single-fact_sheet')) {
              $("#sidebar li.menu-facts").addClass('active');
            } else if ($body.hasClass('single-post') && $('article.category-cultural-events').length > 0) {
              // unhighlight news & latest news in Egypt Today
              $('#sidebar .menu-news,#sidebar .menu-latest-news').removeClass('active');
            } else if ( $body.hasClass('newsletters') || $body.hasClass('register-with-embassy') ) {
              // unhighlight news & latest news in Egypt Today
              $('.menu-overview').removeClass('active');
            }
          }
        }

        ////////////////////////////////////////
        // Contact form
        ////////////////////////////////////////
        $('.menu-contact-us a, .contact-us--display .close-panel').click( function(e) {
          e.preventDefault();
          e.stopPropagation();
          $('body').toggleClass('contact-visible');
          $('body').removeClass('menu-visible');
        });

        $contact = $('.contact-us--display');
        $contact_disclaimer = $contact.find('.disclaimer-wrapper');
        $contact_footer = $contact.find('.gform_footer');
        $contact_address = $contact.find('.contact-address');
        $contact_disclaimer.append($contact_address);
        $contact_disclaimer.append($contact_footer);

        $('.contact-us--display .disclaimer-wrapper').append( $('') );


        //  FORMS SUPPORT
        // need to do this on multiple forms, so use a helper...
        function fullname_maker(firstNameSelector, lastNameSelector, fullnameSelector){
          $(firstNameSelector + ',' + lastNameSelector).blur(function(evt){
            var fn = $(firstNameSelector).val() + ' ' + $(lastNameSelector).val();
            $(fullnameSelector).val(fn);
          });
        }
        // contact (footer) -- egypt community
        fullname_maker("#input_4_1", "#input_4_2", "#input_4_9");
        // contact (footer)
        if( $("#gform_1 li.gform_hidden #input_1_10").length > 0 ){
          // dev db has differnt ID for fullname field
          fullname_maker("#input_1_1", "#input_1_2", "#input_1_10");
        }else{
          fullname_maker("#input_1_1", "#input_1_2", "#input_1_9");
        }
        // Connect (footer)
        fullname_maker("#input_2_1", "#input_2_2", "#input_2_9");
        // END FORMS SUPPORT


        // MIGRATED FROM OLD THEME, MAYBE NO NEEDED (or in the way)
        // fix active menu highlights... most of all of these are due to
        // displaying blog categories outside of the News/Blog menu
        if( $body.hasClass("single-employ_oppty") ){
          $("li.menu-the-embassy").addClass("active");
        }else if( $body.hasClass("category-cultural-events") || $body.hasClass("cultural-events") || $body.hasClass("category-announcements") || $body.hasClass("announcements") ) {
          $("li.menu-news").removeClass("active");
        }
        if( $body.hasClass("announcements") ) {
          $("li.menu-egyptian-community").addClass("active");
        }else if( $body.hasClass("cultural-events") ) {
          $("li.menu-events").addClass("active");
        }

        // GA event tracking for PDF downloads
        $('a[href$=".pdf"]').on('click', function(evt){
          try {
            var $el = $(this),
                href = $el.attr("href"),
                docName;

            //console.log("Clicked PDF", href);evt.preventDefault();

            docName = href.substring(href.lastIndexOf('/')+1);
            //console.log("Sending: 'event', 'document', 'download',", docName);
            ga('send', 'event', 'document', 'download', docName);

          }catch(err){
            console.log("ERROR->", "Runtime error in GA pdf download event tracker: ", err);
          }
        });

        ////////////////////////////////
        // Photo Gallery

        $('.open-lightbox, .close-lightbox').click(function(){
          $('body').toggleClass('image-gallery-visible');
        });

        // listen for escape key when lighbox open
        window.eedcKeyListener = function(evt) {
          if (evt.keyCode === 27) {
            $('body').removeClass('lightbox-is-open');
            jQuery(document).off('keyup', eedcKeyListener);
          }
        };

        window.adjustPrevNext = function() {
          var $curr = $('.image-gallery-modal .modal-wrapper-content li:visible');

          if ($curr.next().length === 0) {
            $("a.next-photo").hide();
          } else {
            $("a.next-photo").show();
          }

          if ($curr.prev().length === 0) {
            $("a.prev-photo").hide();
          } else {
            $("a.prev-photo").show();
          }
        };

        $('.image-gallery-modal .modal-wrapper-content li').hide();

        $('.image-gallery-modal .close-lightbox').click(function(e) {
          e.preventDefault();
          $('body').removeClass('lightbox-is-open');

          // remove escape key listener
          jQuery(document).off('keyup', eedcKeyListener);
        });

        $('.image-gallery-list .open-lightbox').click(function(e) {
          e.preventDefault();
          $('body').addClass('lightbox-is-open');

          $('.image-gallery-modal .modal-wrapper-content li').hide();
          $('#'+$(this).attr('data-photo')).show();

          adjustPrevNext();

          // add escape key listener
          jQuery(document).on('keyup', eedcKeyListener);
        });

        $('.image-gallery-modal .next-photo').click(function(e) {
          e.preventDefault();
          $('.image-gallery-modal .modal-wrapper-content li:visible').hide()
            .next('.image-gallery-modal .modal-wrapper-content li').show();

          adjustPrevNext();
          return false;
        });

        $('.image-gallery-modal .prev-photo').click(function(e) {
          e.preventDefault();
          $('.image-gallery-modal .modal-wrapper-content li:visible').hide()
            .prev('.image-gallery-modal .modal-wrapper-content li').show();

          adjustPrevNext();
          return false;
        });



        // End Photo Gallery
        ////////////////////////////////

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {

        /* Carousel */
        var $slides = $('#carousel-slides li');
        var i = 0;
        var rotation;

        function rotate() {
          $('.dot.active').removeClass('active');
          $($('.dot')[i]).addClass('active');
          $('#carousel-slides li.visible').removeClass('visible');
          $($slides[i]).addClass('visible');
          if( i < $slides.length - 1 ) {
            i++;
          } else {
            i = 0;
          }
        }
        rotation = setInterval( rotate, 6000 );
        rotate();

        $slides.each(function( index ){
          $('.carousel-nav').append('<div id="dot-' + index + '" class="dot"></div>');
          $('#dot-' + index ).click(function(){
            i = index;
            clearInterval( rotation );
            rotate();
            rotation = setInterval( rotate, 6000 );
          });
        });

        $('#dot-0').addClass('active');

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
